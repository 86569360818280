import { BalHeading, BalText } from "@baloise/ds-react";
import { Form, FormValues } from "@baloise-cfa/form-renderer-frontend";
import { graphql, navigate } from "gatsby";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import {
    quoteAcceptanceQuestionsFormConfig,
    QuoteAcceptanceQuestionsState,
    QuoteFormKey,
    QuoteFormState,
    quoteFormState,
    QuoteLayout,
} from "@modules/quote";
import { Footer, Navigation } from "@modules/shared/components";
import { PageNames } from "@modules/shared/enums";
import { getNavigationPages } from "@modules/shared/helpers";
import { PageInterface, PageProps } from "@modules/shared/types";

const validateBusinessRules = (
    acceptanceQuestions: QuoteAcceptanceQuestionsState,
) => {
    const {
        damageHistory = {},
        insurances = {},
        personalInformation = {},
    } = acceptanceQuestions;

    // check damage history
    if (
        (damageHistory.amountDamagedResponsible ?? 0) >= 2 ||
        (damageHistory.amountDamagedNotResponsible ?? 0) >= 5 ||
        (damageHistory.amountExtraDamage ?? 0) >= 5 ||
        (damageHistory.amountDamagedTheft ?? 0) >= 1 ||
        damageHistory.damageCircumstances
    ) {
        return false;
    }

    // check insurances
    if (insurances.insuranceWasDenied || insurances.premiumIncreaseImposed) {
        return false;
    }

    // check personal information
    if (
        personalInformation.practicedProfessions ||
        personalInformation.deniedDrivingLast5years ||
        personalInformation.driverSporadic ||
        !personalInformation.hasDriversLicense ||
        !personalInformation.addressInBelgium
    ) {
        return false;
    }

    return true;
};

const AcceptanceQuestions: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, language, ownPageObjects: allPages } = pageContext;
    const { t } = useTranslation();
    const { nextPage, prevPage } = getNavigationPages(allPages, page);
    const [formState, setFormState] = useRecoilState(quoteFormState);

    const wtcContactUsPage = useMemo(
        () =>
            allPages.find(
                (page: PageInterface) => page.name === PageNames.WTCContactUs,
            ),
        [allPages],
    );

    const fields = useMemo(() => {
        const usualDriver = formState?.drivers?.usualDriver;
        const policyholderInfo = formState?.drivers?.policyholderInfo;

        const usualDriverIsDefined = !!(
            usualDriver?.firstName && usualDriver.lastName
        );
        const policyholderIsDefined = !!(
            policyholderInfo?.policyholder?.firstName &&
            policyholderInfo.policyholder.lastName
        );

        const headDriverIsPolicyHolder =
            !!policyholderInfo?.headDriverIsPolicyHolder;

        return quoteAcceptanceQuestionsFormConfig.createFields(
            headDriverIsPolicyHolder,
            usualDriverIsDefined,
            policyholderIsDefined,
        );
    }, [formState?.drivers?.policyholderInfo, formState?.drivers?.usualDriver]);

    const initialFormValues: QuoteFormState = useMemo(
        () => ({
            ...formState,
            acceptanceQuestions: formState?.acceptanceQuestions ?? {},
        }),
        // Only run this when initializing the pages
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const handleSubmit = (values: FormValues): void => {
        setFormState(values);
        const allBusinessRulesAreMet = validateBusinessRules(
            values.acceptanceQuestions,
        );

        if (!allBusinessRulesAreMet && wtcContactUsPage) {
            navigate(wtcContactUsPage.paths[language]);
            return;
        }

        if (nextPage) {
            navigate(nextPage.paths[language]);
        }
    };

    const handleOnChange = (values: FormValues): void => {
        setFormState(values);
    };

    return (
        <QuoteLayout
            title={t("quote.acceptanceQuestions.title")}
            page={page}
            allPages={allPages}
            language={language}
        >
            <main className="container mt-large is-compact flex flex-direction-column">
                <div className="flex-1">
                    <div className="intro">
                        <BalHeading level="h1">
                            {t("quote.acceptanceQuestions.title")}
                        </BalHeading>
                        <BalText>
                            {t("quote.acceptanceQuestions.intro")}
                        </BalText>
                    </div>
                    <Form
                        scrollToFieldError
                        errorMessageCardTitle={
                            t("all.errormessage.title") as string
                        }
                        errorMessageCardSubTitle={
                            t("all.errormessage.text") as string
                        }
                        formContext={{
                            t,
                            nameSpace: QuoteFormKey.AcceptanceQuestions,
                            fieldWrapper: {
                                optionalLabel: "all.optional",
                            },
                        }}
                        initialValues={initialFormValues}
                        onSubmit={handleSubmit}
                        onChange={handleOnChange}
                        fields={fields}
                    >
                        <Navigation
                            t={t}
                            language={language}
                            nextPageBtnText="quote.tabs.navigation.title"
                            prevPage={prevPage}
                            nextPage={nextPage}
                        />
                    </Form>
                </div>
                <Footer />
            </main>
        </QuoteLayout>
    );
};

export default AcceptanceQuestions;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
